<template>
  <div>
       <!-- 创建 -->
        <div style="width:500px;margin:0 auto;background:#fff;">
            <div style="text-align:left;">
               <el-row>
                    <p style="width:100%;font-size:16px;line-height:40px;height:40px;">等级：</p>
                    
               </el-row>
                <div slot="footer" class="dialog-footer" style="text-align:center;height:60px;line-height:60px;margin-top:60px;">
                    <el-button type="primary" v-if="!saveComplete" @click="save();">保 存</el-button>
                    <el-button type="primary" v-if="saveComplete" @click="exit();">关 闭</el-button>
                </div>
            </div> 
        </div>
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import tools from "../../../extends/tools";
import apis from "../../common/js/api2";

export default {
    props:{
        id:{
            type:Number,
            default:0
        }
    },
    data(){
        return {
            levelList:[]
        }
    },
    methods:{
        exit(){
            this.$emit('shareExit')
        },
        fleshList(){
            this.$emit('fleshList')
        }
    },
    mounted(){
        //判断是修改还是新增
        if(this.id > 0){
            //初始化修改数据,拉取等级列表
            apis.levelSource().then(res=>{
                if(tools.msg(res,this)){
                    this.levelList = res.data;
                }
            }).catch(err=>{
                tools.err(err,this);
            });

        }
    }
}
</script>

<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 78px;
    height: 78px;
    line-height: 78px;
    text-align: center;
  }
  .avatar {
    width: 78px;
    height: 78px;
    display: block;
  }
</style>