<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" >
        <div class="sale" >
            <!-- 顶部导航 -->
            <div class="box-card">
                <!-- 按钮 -->
                <el-row style="height:40px;">
                    <el-button type="primary" class="buttons" @click="add">新增规则</el-button>
                </el-row>
                <el-row>
                    <el-form :inline="true" class="demo-form-inline" >
                        <el-form-item>
                            <el-input
                                    size="small"
                                    placeholder="请输入关键词搜索"
                                    prefix-icon="el-icon-search"
                                    v-model="req.keywords"
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="small" @click="getList">搜索</el-button>
                        </el-form-item>
                    </el-form>
                </el-row>
                <!-- 表格 -->
                <el-table
                        ref="multipleTable"
                        :height="__tableHeight__"
        
        
        
                        :data="tableData"
                        stripe
                        tooltip-effect="dark"
                        style="width: 96%;text-align: center">
                    <el-table-column align="center" type="selection" width="65 "> </el-table-column>
                    <el-table-column label="类型"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.type }}</template>
                    </el-table-column>

                    <el-table-column label="范围"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.min }} &lt; {{scope.row.type}} &lt; {{ scope.row.max }}</template>
                    </el-table-column>
                    <el-table-column label="积分"  min-width="65" align="center">
                        <template slot-scope="scope"> + {{ scope.row.integral }}</template>
                    </el-table-column>

                    <el-table-column fixed="right" label="操作" align="center">
                        <template slot-scope="scope">
                            <!-- <el-button type="text" size="small">
                                数据管理
                            </el-button> -->
                            <el-button type="text" size="small" @click="edit(scope.row)" >
                                编辑
                            </el-button>
                            <el-button type="text" size="small"  @click="remove(scope.row.id)">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 页码 -->
            </div>
        </div>
        <!-- 页码 -->
        <div class="pageDiv" style="">
            <el-pagination
                style="margin:10px 75px 10px 0;"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="req.page"
                :page-size="req.limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                class="flex-right">
            </el-pagination>
        </div>
        <!-- 创建分享 -->
        <el-dialog
        :modal-append-to-body="false"
        title="新增积分规则"
        @close="dataInit"
        :visible.sync="createShareFlag"
        v-if="createShareFlag"
        width="60%"
        center>
             <!-- 创建 -->
            <div style="width:500px;margin:0 auto;">
                <div style="text-align:left;">
                <el-row>
                        <p style="width:100%;font-size:16px;line-height:50px;height:50px;">积分类型：
                            <el-select size="small" style="width:350px;" v-model="saveData.type">
                                    <el-option
                                        v-for="item in typeSource"
                                        :key="item.name"
                                        :label="item.name"
                                        :value="item.name">
                                    </el-option>
                            </el-select>
                        </p>
                        <p style="width:100%;font-size:16px;line-height:50px;height:50px;" >范围：
                            <el-input-number size="small" v-model="saveData.min" :min="1"  label="大于"></el-input-number>
                            <span style="font-size:18px;"> &lt;</span>
                            <span style="color:blue;"> 属性</span><span style="font-size:18px;"> &lt; </span>
                            <el-input-number size="small" v-model="saveData.max"  :min="1"  label="小于"></el-input-number>
                        </p>
                        <p style="width:100%;font-size:16px;line-height:50px;height:50px;">增加积分：
                            <el-input-number size="small" v-model="saveData.integral"  :min="1"  label="小于"></el-input-number>
                        </p>
                </el-row>
                    <div slot="footer" class="dialog-footer" style="text-align:center;height:60px;line-height:60px;margin-top:60px;">
                        <el-button type="primary" @click="save();" v-if="currentId != ''">保 存</el-button>
                        <el-button type="primary" @click="save();" v-if="currentId == ''">新 增</el-button>
                        <el-button type="primary"  @click="dataInit();createShareFlag=false;getList();">关 闭</el-button>
                    </div>
                </div> 
            </div>
        </el-dialog>
    </div>
</template>

<script>
import createShare from './creatDialog';
import shareListFlag from './listDialog';
import apis from "../../common/js/api2";
import tools from "../../../extends/tools";
export default {
props:{

    },
    inject: ['selectSide'], 
    data(){
        return {
            saveData:{
                type:'',
                min:'',
                max:'',
                integral:''
            },
            typeSource:[],
            createShareFlag:false,
            shareListFlag:false,
            currentId:0,
            tableData:[],
            total:0,
            currentPage:1,
            shareId:0,
            statusList:[],
            req:{
                limit:30,
                page:1,
                keywords:'',
                id:''
            }
        }
    },
    components:{
        createShare,shareListFlag
    },
    methods:{
        // 等级数据保存
        save(){
            const loading = this.$loading({
                lock: true,
                text: '保存数据中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            // 根据currentId判断是否大于0来保存数据
            if(this.currentId>0){
                this.saveData.id = this.currentId;
                // 添加等级
                apis.editScore(this.saveData).then(res=>{
                    tools.msg(res,this,1);
                    loading.close();
                }).catch(err=>{
                    tools.err(err,this);
                    loading.close();
                });
                return;
            }

            // 添加等级
            apis.addScore(this.saveData).then(res=>{
                tools.msg(res,this,1);
                loading.close();
                this.dataInit();
            }).catch(err=>{
                tools.err(err,this);
                loading.close();
            });
        },
        handleSizeChange(val) {
            this.req.limit = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.req.page = val;
            this.getList();
        },
        getList(){
            apis.scorelList(this.req).then((res) => {
                this.tableData = res.data.data;
                this.total = res.data.total;
            }).catch((err) => {
                this.$message('网络或程序错误，请稍候重试！', 'error');
            });
        },
        exit(){
            this.$emit('shareExit')
        },
        shareExit(){
            this.createShareFlag = false;
            this.shareListFlag = false;
        },
        dataInit(){
            //初始化
            this.currentId = '';
            this.saveData = tools.copy(this.$options.data().saveData);
        },
        remove(id, key) {
            this.$confirm('确定删除此销售单?', '删除确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                apis.deleteScore({ id: id}).then((info) => {
                    if (info.code == 200) {
                        this.tableData.splice(this.tableData.findIndex(item => item.id === id), 1);
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: info.msg
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        add(){
            this.createShareFlag = true;
            this.currentId = 0;
        },
        edit(item){
            this.createShareFlag = true;
            this.currentId = item.id;
            console.log(this.currentId);
            this.saveData = item;
        }
    },
    mounted(){
        // 获取数据源列表   
        apis.scoreTypeList().then(res=>{
            if(tools.msg(res,this)){
                this.typeSource = res.data;
                console.log(this.typeSource);
            }
        }).catch(err=>{
            tools.err(err,this);
        });

        this.getList();
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.filexls {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}
</style>